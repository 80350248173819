// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

'use strict';

import 'slick-carousel'
import 'cocoen/dist/js/cocoen-jquery.min'
import Cocoen from 'cocoen'
import Masonry from 'masonry-layout'
import Parallax from 'parallax-js';
import simpleParallax from 'simple-parallax-js';
import parallaxis from 'parallaxis'

$(document).ready(function () {
    const learnLink = document.querySelector('#learn_link');
    if (learnLink) {
        learnLink.addEventListener('click', (e) => {
            e.preventDefault();
        })
    }

    $('.people_loving--slider_items').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });
});

$(document).ready(function () {
    //Parallax effect
    const scene = document.querySelector('.first_screen--product');
    if (scene !== null) {
        const parallaxInstance = new Parallax(scene);
    }
});

$(document).ready(function () {
    const parallaxImages = document.querySelectorAll('.parallax');
    const parallaxImagesNoScale = document.querySelectorAll('.parallax-no-scale');

    new simpleParallax(parallaxImages, {
        delay: 0,
        orientation: 'down',
        scale: 1.3,
    });


    new simpleParallax(parallaxImagesNoScale, {
        delay: 0,
        orientation: 'down',
        scale: 1.2,
        overflow: true
    });

    parallaxis();
});

$(document).ready(function () {
    $('.ps_product--image_slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: false,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        dots: true
    });
});

$(document).ready(function () {
    //Fix Cocoen Slider
    const cocoenItem = document.querySelector('.cocoen');

    if (cocoenItem !== null) {
        new Cocoen(cocoenItem);
    }
});

$(document).ready(function () {
    function f_acc() {
        $(this).next().slideToggle(500).parent().toggleClass('active');
    }

    $('.faq_item .faq_head').on('click', f_acc);

    $('.checkout_review--product .checkout_review--products_title').on('click', f_acc);
    $('.faq_item .faq_body').on('click', f_acc);
    $('.ps_product--ingredients_expand .ps_product--ingredients_expand--head').on('click', f_acc);
    $('.popup_add_product--expand .popup_add_product--expand_head').on('click', f_acc);
});

$(document).ready(function () {
    const ingredientsInfoArr = document.querySelectorAll('.ps_ingredients--item_info');

    for (let i = 0; i < ingredientsInfoArr.length; i++) {
        let offset = ingredientsInfoArr[i].getBoundingClientRect().right;
        if (offset > 1400) {
            ingredientsInfoArr[i].classList.add('left-side');
        }
    }
});

$(document).ready(function () {
    const preloader = document.querySelector('.preloader');
    if (preloader !== null) {
        setTimeout((e) => {
            preloader.classList.remove('active');
        }, 2500);
    }
});

$(document).ready(function () {
    const burger = document.querySelector('.hamburger');
    const header = document.querySelector('header');


    if (burger !== null && header !== null) {
        burger.addEventListener('click', (e => {
            e.preventDefault();
            header.classList.toggle('active_menu');
        }))
    }
});

$(document).ready(function () {
    const customerNextShipmentInput = document.querySelectorAll('.add_next--shipment input[type="number"]');
    const customerNextShipmentIncrement = document.querySelectorAll('.add_next--shipment .product_count--increment');
    const customerNextShipmentDecrement = document.querySelectorAll('.add_next--shipment .product_count--decrement');

    for (let i = 0; i < customerNextShipmentIncrement.length; i++) {
        customerNextShipmentIncrement[i].addEventListener('click', e => {
            e.preventDefault();
            incrementFunc(customerNextShipmentInput[i], customerNextShipmentInput[i].value);
        })
    }

    for (let i = 0; i < customerNextShipmentDecrement.length; i++) {
        customerNextShipmentDecrement[i].addEventListener('click', e => {
            e.preventDefault();
            decrementFunc(customerNextShipmentInput[i], customerNextShipmentInput[i].value);
        })
    }


    const incrementFunc = (item, value) => {
        value++;
        item.value = value;
    }

    const decrementFunc = (item, value) => {
        if (value >= 2) {
            value--;
            item.value = value;
        } else {
            value = 1;
            item.value = value;
        }
    }
});

$(document).on('turbolinks:load', function () {
    // Fresh Chat init
    function initFreshChat() {
        window.fcWidget.init({token: "e34931ac-a9fc-4a8c-a2bd-1d4a8d35248e", host: "https://wchat.freshchat.com"});
    }

    function initialize(i, t) {
        var e;
        e = i.createElement("script");
        e.id = t;
        e.async = !0;
        e.src = "https://wchat.freshchat.com/js/widget.js";
        e.onload = initFreshChat;
        i.head.appendChild(e);
    }

    function initiateCall() {
        if (window.fcWidget) {
            window.fcWidget.destroy();
            window.fcWidget = null;
            $('#fc_frame').remove();
            $('#freshchat-js-sdk').remove();
        }
        initialize(document, "freshchat-js-sdk")
    }

    initiateCall();

    const openChat = document.querySelectorAll('.open_chat');
    for (let i = 0; i < openChat.length; i++) {
        openChat[i].addEventListener('click', function (e) {
            e.preventDefault();
            window.fcWidget.open();
            document.querySelector('#fc_frame').style.display = "block";
        })
    }
});

$(document).ready(function () {
    // Facebook events
    const startFreeTrialBtn = document.querySelector('.start_free_trial');
    const registerForm = document.querySelector('.new_spree_user');

    if (startFreeTrialBtn !== null) {
        startFreeTrialBtn.addEventListener('click', e => {
            fbq('track', 'Lead');
        })
    }

    if (registerForm !== null) {
        registerForm.addEventListener('submit', e => {
            // TODO: not correct as the form might return errors - should be handeld from the backend
            fbq('track', 'CompleteRegistration');
        })
    }
});

$(document).ready(function () {
    const MasonryGrid = document.querySelector('.grid');
    if (MasonryGrid && document.readyState !== 'loading') {
        new Masonry('.grid', {
            itemSelector: '.reviews_box--item',
            columnWidth: '.reviews_box--item',
            gutter: 20
        });

        setTimeout(() => {
            new Masonry('.grid', {
                itemSelector: '.reviews_box--item',
                columnWidth: '.reviews_box--item',
                gutter: 20
            });
        }, 500)
    }
});

$(document).ready(function () {
    if (window.innerWidth < 480) {
        const preloaderDiv = document.querySelector('.preloader');
        if (preloaderDiv) {
            preloaderDiv.remove();
        }
    }
});


//Account page JS
$(document).ready(function () {
    //Subscription Frequency Edit
    const popupProductAmountInput = document.querySelectorAll('.popup_edit_products--product--amount input[type="number"]');
    const popupProductAmountIncrement = document.querySelectorAll('.popup_edit_products--product--amount .amount_plus');
    const popupProductAmountDecrement = document.querySelectorAll('.popup_edit_products--product--amount .amount_minus');
    const subscriptionFrequencyEditBtn = document.querySelector('.subscription_frequency--btn');
    const subscriptionFrequencyEditBox = document.querySelector('.frequency_day--edit-box');
    const subscriptionFrequencyCurrent = document.querySelector('.account_subscription--frequency_day span');
    const subscriptionFrequencyItemsArr = document.querySelectorAll('.frequency_day--edit-box_item');
    const successPopup = document.querySelector('.popup_success');
    const allPopupArr = document.querySelectorAll('.popup');
    const allBtnCloseArr = document.querySelectorAll('.popup_prev--btn');
    const addProductPopup = document.querySelector('.popup.add_product');
    const editProductBtn = document.querySelector('.account_product_edit--btn');
    const editProductPopup = document.querySelector('.edit_product');
    const cancelSubscriptionBtn = document.querySelector('.cancel_subscription--btn');
    const cancelSubscriptionPopup = document.querySelector('.cancel_subscription');
    const closePopupBtn = document.querySelectorAll('.popup_close');
    const allPopupsArr = document.querySelectorAll('.popup');
    const pauseSubscriptionBtn = document.querySelector('.pause_now--btn');
    const cancelFirstStep = document.querySelector('.cs_first--step');
    const cancelSecondStep = document.querySelector('.cs_second--step');
    const continueCancelBtn = document.querySelector('.continue_to_cancel--btn');
    const continueCancelOptionsArr = document.querySelectorAll('.continue_cs--options .continue_cs--option');
    const continueCancelOptionsDetailArr = document.querySelectorAll('.cs_option--detail');
    const continueCancelOptionsDetailPrevBtnArr = document.querySelectorAll('.cs_option--detail .popup_prev--btn-detail');
    const continueCancelOptionsPrevBtn = document.querySelector('.popup_prev--btn--options');
    const returnToAccountBtn = document.querySelectorAll('.close_popup');
    const changeBillingIntervalForm = document.querySelector('#change_billing_interval_form');
    const editShippingAddressPopup = document.querySelector('.edit_shipping_address');
    const editShippingAddressPopupBtn = document.querySelector('.edit_shipping_address--btn');
    const editBillingAddressPopup = document.querySelector('.edit_billing_address');
    const editBillingAddressPopupBtn = document.querySelector('.edit_billing_address--btn');
    const sameBillingCheckbox = document.querySelector('#same_billing');
    const editPaymentMethodPopup = document.querySelector('.edit_payment_method');
    const editPaymentMethodPopupBtn = document.querySelector('.edit_payment_method--btn');
    const editAccountInfoPopup = document.querySelector('.edit_account_info');
    const editAccountInfoPopupBtn = document.querySelectorAll('.edit_account_info--btn');
    const reactivatePopupBtn = document.querySelector('.reactivate--btn');
    const reactivatePopup = document.querySelector('.popup.reactivate');
    const reactivatePopupBox = document.querySelector('.reactivate_box');
    const reactivatePopupProductsArr = document.querySelectorAll('.reactivate_popup_edit_products--item');
    const accountBox = document.querySelector('.account');
    const returnToFrequency = document.querySelectorAll('.return_to_frequency');

    if (returnToFrequency.length > 0) {
        for (let i = 0; i < returnToFrequency.length; i++) {
            returnToFrequency[i].addEventListener('click', (e => {
                e.preventDefault();
                cancelSecondStep.classList.add('is-hide');
                for (let p = 0; p < continueCancelOptionsDetailArr.length; p++) {
                    if (!continueCancelOptionsDetailArr[p].classList.contains('is-hide')) {
                        continueCancelOptionsDetailArr[p].classList.add('is-hide');
                    }
                }
                cancelFirstStep.classList.remove('is-hide');
            }))
        }
    }

    if (reactivatePopupProductsArr.length > 0) {
        for (let i = 0; i < reactivatePopupProductsArr.length; i++) {
            reactivatePopupProductsArr[i].addEventListener('click', e => {
                e.preventDefault();
                reactivatePopup.classList.remove('is-active');
                accountBox.classList.add('is-hide');
                reactivatePopupBox.classList.remove('is-hide');
                window.scroll(0, 0);
            })
        }
    }


    const openSomething = (popup, btn) => {
        if (btn !== null && popup !== null) {
            btn.addEventListener('click', (e => {
                e.preventDefault();
                popup.classList.add('is-active');
            }))
        }
    }

    const openSomethingInArr = (popup, btn) => {
        if (btn.length > 0 && popup !== null) {
            for (let i = 0; i < btn.length; i++) {
                btn[i].addEventListener('click', (e => {
                    e.preventDefault();
                    popup.classList.add('is-active');
                }))
            }
        }
    }

    openSomething(editShippingAddressPopup, editShippingAddressPopupBtn);
    openSomething(editBillingAddressPopup, editBillingAddressPopupBtn);
    openSomething(cancelSubscriptionPopup, cancelSubscriptionBtn);
    openSomething(editProductPopup, editProductBtn);
    //openSomething(reactivatePopup, reactivatePopupBtn);
    openSomethingInArr(editAccountInfoPopup, editAccountInfoPopupBtn);


    // display or hide billing details based on current addresses
    if (sameBillingCheckbox !== null) {
        displayBilling(sameBillingCheckbox);
        sameBillingCheckbox.addEventListener('change', function () {
            displayBilling(this);
        });
    }

    function displayBilling(checkbox) {
        if (checkbox.checked) {
            $('.edit_billing_address .popup_wrap form .account_edit--input').hide();
        } else {
            $('.edit_billing_address .popup_wrap form .account_edit--input').show();
        }
    }


    if (changeBillingIntervalForm) {
        changeBillingIntervalForm.addEventListener('submit', function (event) {
            this.querySelector('input[name="date"]').value = ''; // ADD SELECTED DATE FROM CALENDAR
            popupCalendar.classList.remove('is-active');
        });
    }


    if (returnToAccountBtn.length > 0) {
        for (let i = 0; i < returnToAccountBtn.length; i++) {
            returnToAccountBtn[i].addEventListener('click', (e => {
                e.preventDefault();
                for (let p = 0; p < allPopupsArr.length; p++) {
                    if (allPopupsArr[p].classList.contains('is-active')) {
                        allPopupsArr[p].classList.remove('is-active');
                    }
                }
            }))
        }
    }

    //Go to prev step in cancel popups
    if (continueCancelOptionsPrevBtn !== null) {
        continueCancelOptionsPrevBtn.addEventListener('click', (e => {
            e.preventDefault();
            cancelFirstStep.classList.remove('is-hide');
            cancelSecondStep.classList.add('is-hide');
        }))
    }

    for (let i = 0; i < continueCancelOptionsDetailPrevBtnArr.length; i++) {
        continueCancelOptionsDetailPrevBtnArr[i].addEventListener('click', (e => {
            e.preventDefault();
            continueCancelOptionsDetailArr[i].classList.add('is-hide');
            cancelSecondStep.classList.remove('is-hide');
        }))
    }

    //Show Cancel details
    if (continueCancelOptionsArr.length > 0) {
        for (let i = 0; i < continueCancelOptionsArr.length; i++) {
            continueCancelOptionsArr[i].addEventListener('click', (e => {
                e.preventDefault();
                cancelSecondStep.classList.add('is-hide');
                continueCancelOptionsDetailArr[i].classList.remove('is-hide');
            }))
        }
    }

    if (continueCancelBtn !== null) {
        continueCancelBtn.addEventListener('click', (e => {
            e.preventDefault();
            cancelFirstStep.classList.add('is-hide');
            cancelSecondStep.classList.remove('is-hide');
        }))
    }


    //funcs that close all popup when clicked to close btn
    if (closePopupBtn.length > 0) {
        for (let i = 0; i < closePopupBtn.length; i++) {
            closePopupBtn[i].addEventListener('click', (e => {
                e.preventDefault();
                for (let p = 0; p < allPopupsArr.length; p++) {
                    if (allPopupsArr[p].classList.contains('is-active')) {
                        allPopupsArr[p].classList.remove('is-active');
                    }
                }
            }))
        }
    }
    //end funcs that close all popup when clicked to close btn

    for (let i = 0; i < allBtnCloseArr.length; i++) {
        allBtnCloseArr[i].addEventListener('click', (e => {
            e.preventDefault();
            for (let p = 0; p < allPopupArr.length; p++) {
                if (allPopupArr[p].classList.contains('is-active')) {
                    allPopupArr[p].classList.remove('is-active');
                }
            }
        }))
    }

    if (subscriptionFrequencyEditBtn !== null || subscriptionFrequencyCurrent) {
        subscriptionFrequencyEditBtn.addEventListener('click', (e => {
            subscriptionFrequencyEditBox.classList.toggle('is-active');
        }))

        subscriptionFrequencyCurrent.addEventListener('click', (e => {
            subscriptionFrequencyEditBox.classList.toggle('is-active');
        }))
    }

    const removeActive = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].classList.remove('is-active');
        }
    }


    const showSuccess = () => {
        if (!successPopup.classList.contains('is-active')) {
            successPopup.classList.add('is-active');
            setTimeout(() => {
                successPopup.classList.remove('is-active');
            }, 1500);
        }
    }


    //Build calendar
    const newShippingDateBtn = document.querySelector('.new_shipping_date--btn');
    const popupCalendar = document.querySelector('.choose_a_date');
    const calendarCloseBtn = document.querySelector('.popup_close');

    if (newShippingDateBtn !== null) {
        newShippingDateBtn.addEventListener('click', (e => {
            popupCalendar.classList.add('is-active');
        }))
    }

    if (calendarCloseBtn !== null) {
        calendarCloseBtn.addEventListener('click', (e => {
            popupCalendar.classList.remove('is-active');
        }))
    }

    //End build calendar


    const newCalendarElem = document.querySelector('.new_calendar');
    const calendarError = document.querySelector('.calendar-error');
    const newDateSubmit = document.querySelector('#change_billing_interval_form');
    const calendarJs = document.querySelectorAll('.jsCalendar table');

    const myCalendar = jsCalendar.new(newCalendarElem);

    if (calendarJs.length >= 1) {
        calendarJs[0].remove();
    }
    // Create the calendar

    let confirmDate = null;

    //Make min and max value (min: current, max: current + 6 months)
    const nowNewDate = new Date();
    nowNewDate.setMonth(nowNewDate.getMonth() + 6);
    const ddd = nowNewDate.getDate();
    const mmm = nowNewDate.getMonth() + 1;
    const yy = nowNewDate.getFullYear();
    const sixMonthDate = ddd + '/' + mmm + '/' + yy;
    myCalendar.min("now");
    myCalendar.max(sixMonthDate);

    // Set clicked data
    myCalendar.onDateClick(function (event, date) {

        let selectedDate = jsCalendar.tools.dateToString(date, "D/M/yyyy");

        let from = new Date();
        const ddd = from.getDate();
        const mmm = from.getMonth() + 1;
        const yy = from.getFullYear();
        const fromDate = ddd + '/' + mmm + '/' + yy;
        let toDate = sixMonthDate;
        let go = checkDate(fromDate, toDate, selectedDate);

        if (go) {
            myCalendar.set(date);
            confirmDate = jsCalendar.tools.dateToString(date, "yyyy/MM/DD");
            if (calendarError.classList.contains('is-active')) {
                calendarError.classList.remove('is-active');
            }
        } else {
            calendarError.classList.add('is-active');
        }
    });


    //Fetch: Change subscription date
    if (newDateSubmit) {
        newDateSubmit.addEventListener('submit', (e => {
            let button = newDateSubmit.querySelector('button');
            disableButtons(button);
            e.preventDefault();
            showSpinner()
            let showResponseError = false;
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + window.spree_api_key
                },
                body: JSON.stringify({
                    "date": confirmDate
                })
            };
            fetch('/api/subscriptions/change_billing_anchor', requestOptions)
                .then((response) => {
                    showSpinner()
                    if (response.status >= 200 && response.status <= 300) {
                        showSuccess();
                        setTimeout(() => location.reload(), 1000);
                    } else {
                        showResponseError = true;
                    }
                    return response
                })
                .then(response => response.json())
                .then(response => {
                    if (showResponseError) {
                        showError(response.error)
                    }
                });
        }))
    }

    //Check if date are in range
    const checkDate = (From, To, Check) => {
        let dateFrom = From;
        let dateTo = To;
        let dateCheck = Check;


        let d1 = dateFrom.split("/");
        let d2 = dateTo.split("/");
        let c = dateCheck.split("/");

        let from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);  // -1 because months are from 0 to 11
        let to = new Date(parseInt(d2[2]), parseInt(d2[1]) - 1, d2[0]);
        let check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
        if (check > from && check < to) {
            return true
        } else {
            return false
        }
    }


    //Reset data to current
    const closeCalendar = document.querySelector('.choose_a_date .popup_close');
    if (closeCalendar) {
        closeCalendar.addEventListener('click', e => {
            e.preventDefault();
            myCalendar.set(new Date());
        })
    }


    //Fetch: Pause subscription
    if (pauseSubscriptionBtn !== null) {
        let selectedValue = '2 weeks';
        let numWeeks = 2;
        const pauseSubSelect = document.querySelector('#pause_subscription');
        const pauseSubSelectDateText = document.querySelector('#pause_subscription--date span');
        const pauseSubSelectDateText1 = document.querySelector('#pause_subscription--date1 span');
        let currentNextDate = new Date();
        currentNextDate.setDate(currentNextDate.getDate() + numWeeks * 7);
        if (pauseSubSelectDateText.innerHTML.trim().length != 0) {
            currentNextDate = new Date(pauseSubSelectDateText.innerHTML)
            pauseSubSelectDateText.innerHTML = currentNextDate.toLocaleString('en-US',
                {month: 'long', day: 'numeric'}
            );
        } else {
            pauseSubSelectDateText.innerHTML = currentNextDate.toLocaleString('en-US',
                {month: 'long', day: 'numeric'}
            );
        }
        if (pauseSubSelectDateText1) {
            pauseSubSelectDateText1.innerHTML = currentNextDate.toLocaleString('en-US',
                {month: 'long', day: 'numeric'}
            );
        }
        
        pauseSubSelect.addEventListener('change', (e => {
            selectedValue = pauseSubSelect.value;
            numWeeks = pauseSubSelect.options[pauseSubSelect.selectedIndex].getAttribute('data-value');
            currentNextDate = new Date();
            if (numWeeks == 2) {
                currentNextDate.setDate(currentNextDate.getDate() + numWeeks * 7);
            } else {
                currentNextDate.setMonth(currentNextDate.getMonth() + (numWeeks -= 2));
            }
            pauseSubSelectDateText.innerHTML = currentNextDate.toLocaleString('en-US',
                {month: 'long', day: 'numeric'}
            );
        }))

        pauseSubscriptionBtn.addEventListener('click', (e => {
            disableButtons(pauseSubscriptionBtn);
            e.preventDefault();
            showSpinner()
            let showResponseError = false;
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + window.spree_api_key
                },
                body: JSON.stringify({
                    "pause_for": selectedValue
                })
            };
            fetch('/api/subscriptions/pause', requestOptions)
                .then((response) => {
                    showSpinner()
                    if (response.status >= 200 && response.status <= 300) {
                        showSuccess();
                        setTimeout(() => location.reload(), 1000);
                    } else {
                        showResponseError = true;
                    }
                    return response
                })
                .then(response => response.json())
                .then(response => {
                    if (showResponseError) {
                        showError(response.error)
                    }
                })
            ;

        }))
    }


    //Fetch: Skip shipment
    const skipNext = document.querySelector('.account_button--skip-next form');
    if (skipNext) {
        skipNext.addEventListener('submit', (e => {
            let button = skipNext.querySelector('button');
            disableButtons(button);
            e.preventDefault();
            showSpinner()
            let showResponseError = false;
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + window.spree_api_key
                },
                body: JSON.stringify({})
            };
            fetch('/api/subscriptions/skip', requestOptions)
                .then((response) => {
                    showSpinner()
                    if (response.status >= 200 && response.status <= 300) {
                        showSuccess();
                        setTimeout(() => location.reload(), 1000);
                    } else {
                        showResponseError = true;
                    }
                    return response
                })
                .then(response => response.json())
                .then(response => {
                    if (showResponseError) {
                        showError(response.error)
                    }
                })
            ;
        }))
    }

    //Fetch: Change Frequency
    const frequencyDayArr = document.querySelectorAll('.frequency_day--edit-box_item span');
    if (frequencyDayArr.length > 0) {
        for (let i = 0; i < frequencyDayArr.length; i++) {
            frequencyDayArr[i].addEventListener('click', (e => {
                e.preventDefault();
                showSpinner()
                let value = frequencyDayArr[i].getAttribute('data-value').toString();
                let showResponseError = false;
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + window.spree_api_key
                    },
                    body: JSON.stringify(
                        {"subscription_type": value}
                    )
                };
                fetch('/api/subscriptions/change_billing_interval', requestOptions)
                    .then((response) => {
                        showSpinner()
                        if (response.status >= 200 && response.status <= 300) {
                            removeActive(subscriptionFrequencyItemsArr);
                            subscriptionFrequencyItemsArr[i].classList.add('is-active');
                            subscriptionFrequencyCurrent.innerHTML = subscriptionFrequencyItemsArr[i].innerHTML;
                            subscriptionFrequencyEditBox.classList.remove('is-active');
                            showSuccess();
                        } else {
                            showResponseError = true;
                        }
                        return response
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (showResponseError) {
                            showError(response.error)
                        }
                    })
                ;
            }))
        }
    }

    const errorPopup = document.querySelector('.popup_error');

    const showError = (error) => {
        if (!errorPopup.classList.contains('is-active')) {
            if (error) {
                errorPopup.querySelector('p').innerText = '';
                errorPopup.querySelector('p').innerText = error;
            } else {
                errorPopup.querySelector('p').innerText = '';
                errorPopup.querySelector('p').innerText = 'Something went wrong';
            }

            errorPopup.classList.add('is-active');
            setTimeout(() => {
                errorPopup.classList.remove('is-active');
            }, 2500);
        }
    }


    //Fetch: Resume
    const resumeBtn = document.querySelector('.account_resume--btn');
    if (resumeBtn) {
        resumeBtn.addEventListener('click', (e => {
            disableButtons(resumeBtn);
            e.preventDefault();
            showSpinner()
            let showResponseError = false;
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + window.spree_api_key
                },
                body: JSON.stringify(
                    {}
                )
            };
            fetch('/api/subscriptions/resume', requestOptions)
                .then((response) => {
                    showSpinner()
                    if (response.status >= 200 && response.status <= 300) {
                        showSuccess();
                        setTimeout(() => location.reload(), 1000);
                    } else {
                        showResponseError = true;
                    }
                    return response
                })
                .then(response => response.json())
                .then(response => {
                    if (showResponseError) {
                        showError(response.error)
                    }
                })
            ;
        }))
    }

    //Add product popup creation
    const addProductsArr = document.querySelectorAll('.account_all--products_item');

    const getData = (index) => {
        //Enable when src will be ready
        let img = addProductsArr[index].querySelector('.account_all--products_img img');
        let dataImgSrc = "";
        if (img !== null)
            dataImgSrc = img.getAttribute('src');
        let dataDescription = addProductsArr[index].getAttribute('data-description');
        let dataOptions = addProductsArr[index].getAttribute('data-options');
        let dataRecurring = addProductsArr[index].getAttribute('data-recurring-price-id');
        let dataOnePrice = addProductsArr[index].getAttribute('data-once-price-id');
        let dataRecurringPrice = addProductsArr[index].getAttribute('data-recurring-price');
        let dataOneTimePrice = addProductsArr[index].getAttribute('data-once-price');
        let dataName = addProductsArr[index].querySelector('.account_all--products_name span').innerText;
        createPopup(dataName, dataOptions, dataImgSrc, dataDescription, dataRecurring, dataOnePrice, dataRecurringPrice, dataOneTimePrice)
    }

    const createPopup = (name, options, dataImgSrc, description, recurring, onePrice, dataRecurringPrice, dataOneTimePrice) => {
        const productImg = addProductPopup.querySelector('.popup_add_product--img img');
        //Enable when src will be ready
        productImg.setAttribute('src', dataImgSrc);
        const productName = addProductPopup.querySelector('.popup_add_product--expand_name span');
        productName.innerHTML = name;
        const productOptions = addProductPopup.querySelector('.popup_add_product--expand_oz span');
        productOptions.innerHTML = options;
        const productDescription = addProductPopup.querySelector('.popup_add_product--expand_text');
        const descriptionFormat = description.split(/\r?\n/);
        let formatted = [];
        productDescription.innerHTML = ''; // Clear text
        // Push text into popup
        for (let i = 0; i < descriptionFormat.length; i++) {
            formatted.push(`<p>${descriptionFormat[i]}</p>`);
            productDescription.insertAdjacentHTML('beforeend', `<p>${descriptionFormat[i]}</p>`);
        }

        addProductPopup.setAttribute('data-recurring-price-id', recurring);
        addProductPopup.setAttribute('data-once-price-id', onePrice);
        let recurringBtn = addProductPopup.querySelector('.add_product_to_subscription');
        let oneTimeBtn = addProductPopup.querySelector('.add_product_to_next_order');
        if (recurring && onePrice) {
            addProductPopup.classList.add('is-active');
            if (recurringBtn.classList.contains('hidden')) {
                recurringBtn.classList.remove('hidden')
            }
            if (oneTimeBtn.classList.contains('hidden')) {
                oneTimeBtn.classList.remove('hidden')
            }
        } else if (recurring) {
            addProductPopup.classList.add('is-active');
            if (recurringBtn.classList.contains('hidden')) {
                recurringBtn.classList.remove('hidden')
            }
            oneTimeBtn.classList.add('hidden');
        } else if (onePrice) {
            addProductPopup.classList.add('is-active');
            if (oneTimeBtn.classList.contains('hidden')) {
                oneTimeBtn.classList.remove('hidden')
            }
            recurringBtn.classList.add('hidden');
        }
        const priceSpan = addProductPopup.querySelector('.popup_add_product--expand_price span');
        if (dataRecurringPrice === dataOneTimePrice) {
            priceSpan.innerHTML = `$${dataRecurringPrice}`;
        } else if (dataRecurringPrice !== '' && dataOneTimePrice !== '') {
            priceSpan.innerHTML = `$${dataRecurringPrice} - $${dataOneTimePrice}`;
        } else if (dataRecurringPrice !== '') {
            priceSpan.innerHTML = `$${dataRecurringPrice}`;
        } else if (dataOneTimePrice !== '') {
            priceSpan.innerHTML = `$${dataOneTimePrice}`;
        }
    }

    const addProductsBtnArr = document.querySelectorAll('.account_all--products_item .account_add--btn')
    for (let i = 0; i < addProductsBtnArr.length; i++) {
        addProductsBtnArr[i].addEventListener('click', (e => {
            e.preventDefault();
            getData(i);
        }))
    }

    //Add to next subscription
    let subId = null;
    // Recurring
    const addToNextSubBtn = document.querySelector('.add_product_to_subscription');
    // One time
    const addOneTimeOrderBtn = document.querySelector('.add_product_to_next_order');


    // Get subscriber ID
    const fetchSubId = () => {
        subId = document.querySelector('.account').getAttribute('data-sub');
    }

    fetchSubId();

    // Recurring Btn event listener
    addToNextSubBtn.addEventListener('click', (e => {
        disableButtons(addToNextSubBtn);
        e.preventDefault();
        addToNextSub();
    }))
    // Recurring FETCH
    const addToNextSub = (quantity) => {
        const addProductPopup = document.querySelector('.popup.add_product');
        let value = addProductPopup.getAttribute('data-recurring-price-id');
        showSpinner()
        let showResponseError = false;

        fetch(`/api/subscriptions/${subId}/subscription_items`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + window.spree_api_key
            },
            body: JSON.stringify(
                {
                    "subscription_item": {
                        "price_id": value,
                        "quantity": parseInt(quantity) || 1
                    }
                }
            )
        })
            .then((response) => {
                showSpinner()
                if (response.status >= 200 && response.status <= 300) {
                    showSuccess();
                    setTimeout(() => location.reload(), 1000);
                } else {
                    showResponseError = true;
                }
                return response
            })
            .then(response => response.json())
            .then(response => {
                if (showResponseError) {
                    showError(response.error)
                }
            })
        ;
    }

    // One time order Btn event listener
    addOneTimeOrderBtn.addEventListener('click', (e => {
        disableButtons(addOneTimeOrderBtn);
        e.preventDefault();
        addOneTimeOrder();
    }))
    // One time product FETCH
    const addOneTimeOrder = (quantity) => {
        const addProductPopup = document.querySelector('.popup.add_product');
        let value = addProductPopup.getAttribute('data-once-price-id');
        showSpinner()
        let showResponseError = false;

        fetch(`/api/subscriptions/${subId}/invoice_items`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + window.spree_api_key
            },
            body: JSON.stringify(
                {
                    "invoice_item": {
                        "price_id": value,
                        "quantity": parseInt(quantity) || 1
                    }
                }
            )
        })
            .then((response) => {
                showSpinner()
                if (response.status >= 200 && response.status <= 300) {
                    showSuccess();
                    setTimeout(() => location.reload(), 1000);
                } else {
                    showResponseError = true;
                }
                return response
            })
            .then(response => response.json())
            .then(response => {
                if (showResponseError) {
                    showError(response.error)
                }
            })
        ;
    }

    const showSpinner = () => {
        const spinner = document.querySelector('.spinner');
        if (!spinner.classList.contains('active')) {
            spinner.classList.add('active');
        } else {
            spinner.classList.remove('active');
        }
    }

    const returnToCalendar = document.querySelectorAll('.return_to_calendar');

    if (returnToCalendar.length > 0) {
        for (let i = 0; i < returnToCalendar.length; i++) {
            returnToCalendar[i].addEventListener('click', (e => {
                e.preventDefault();
                cancelSubscriptionPopup.classList.remove('is-active')
                popupCalendar.classList.add('is-active');
            }))
        }
    }

    if (editPaymentMethodPopupBtn !== null) {
        editPaymentMethodPopupBtn.addEventListener('click', (e => {
            e.preventDefault();
            editPaymentMethodPopup.classList.add('is-active');
        }))
    }

    let manageCardExpiryElement;
    let manageCardCvcElement;

    const editCardBtn = document.querySelector('.edit_payment_method--btn');

    if (editCardBtn !== null) {
        editCardBtn.addEventListener('click', (e => {
            e.preventDefault();
            editCard();
        }))
    }


    const editCard = () => {
        getSetupIntentSecret();
        initializeStripeCard();
    }


    const initializeStripeCard = () => {
        window.manage_stripe = Stripe(window.stripe_publishable_key);
        let manageElements = window.manage_stripe.elements();

        let style = {
            base: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '20px',
                color: '#000000',
                '::placeholder': {
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '20px'
                }
            },
        }

        window.cardNumberElement = manageElements.create('cardNumber', {style: style});
        manageCardExpiryElement = manageElements.create('cardExpiry', {style: style});
        manageCardCvcElement = manageElements.create('cardCvc', {style: style});

        window.cardNumberElement.mount('#card_number', {style: style});
        manageCardExpiryElement.mount('#expiry_date', {style: style});
        manageCardCvcElement.mount('#cvc', {style: style});
    }

    const getSetupIntentSecret = () => {
        $.ajax({
            type: "POST",
            contentType: 'application/json',
            url: '/api/subscriptions/create_setup_intent',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                'Authorization': 'Bearer ' + window.spree_api_key
            },
            success: function (response) {
                let cardNumberDiv = document.querySelector('#card_number')
                cardNumberDiv.setAttribute('data-secret', response.secret);
            },
            error: function (xhr) {
                let card_error = document.querySelector('#card_error');
                card_error.innerHTML = '<ul><li>Something went wrong, please contact support.</li></ul>';
                card_error.scrollIntoView();
            }
        });
    }


    const paymentForm = document.querySelector('#edit_payment_method--form');
    if (paymentForm) {
        paymentForm.addEventListener('submit', (e => {
            let button = paymentForm.querySelector('button');
            console.log(button)
            disableButtons(button);
            e.preventDefault();
            submitBillingDetails(editPaymentMethodPopup);
        }))
    }

    const pullBillingAddress = () => {
        const billingAddressForm = document.querySelector('#billing_address_form')
        const countrySelect = billingAddressForm.querySelector('select#user_bill_address_attributes_country_id');
        const countryCode = $(countrySelect).find('option:selected').data('code');
        const stateSelect = billingAddressForm.querySelector('select#user_bill_address_attributes_state_id');
        const stateName = $(stateSelect).find('option:selected').data('name');

        let hash = {}
        hash = {
            address: {
                line1: billingAddressForm.querySelector('input#user_bill_address_attributes_address1').value,
                line2: billingAddressForm.querySelector('input#user_bill_address_attributes_address2').value,
                city: billingAddressForm.querySelector('input#user_bill_address_attributes_city').value,
                postal_code: billingAddressForm.querySelector('input#user_bill_address_attributes_zipcode').value,
                country: countryCode,
                state: stateName
            },
            phone: billingAddressForm.querySelector('input#user_bill_address_attributes_phone').value
        }
        hash['name'] = paymentForm.querySelector('#name_on_card').value;
        return hash;
    }

    const submitBillingDetails = () => {
        showSpinner()
        // if user is signed in
        let available_stripe = window.manage_stripe
        if (available_stripe === undefined)
            available_stripe = window.manage_stripe
        available_stripe.confirmCardSetup(
            document.querySelector('#card_number').dataset.secret,
            {
                payment_method: {
                    card: window.cardNumberElement, // can use any element, it pulls the data from the others
                    billing_details: pullBillingAddress()
                }
            }
        ).then(function (result) {
            if (result.error) {
                showSpinner()
                showError(result.error.message);
            } else {
                console.log(result.setupIntent.payment_method)
                $.ajax({
                    type: "POST",
                    contentType: 'application/json',
                    url: '/account/update_card',
                    headers: {
                        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
                        'Authorization': 'Bearer ' + window.spree_api_key
                    },
                    data: JSON.stringify({
                        "payment_method": result.setupIntent.payment_method
                    }),
                    success: function (response) {
                        showSpinner()
                        showSuccess();
                        setTimeout(() => location.reload(), 2000);
                    },
                    error: function (xhr) {
                        showSpinner()
                        showError("Something went Wrong");
                    }
                });
            }
        });
    }

    //Change Amount in Current Products
    const popupProductEdit = document.querySelectorAll('.one_time_product');

    for (let i = 0; i < popupProductAmountIncrement.length; i++) {
        popupProductAmountIncrement[i].addEventListener('click', e => {
            e.preventDefault();
            let count = incrementFunc(popupProductAmountInput[i], popupProductAmountInput[i].value);
            let priceDiv = popupProductEdit[i].querySelector('.popup_edit_products--product--info_price');
            let price = Number(popupProductEdit[i].getAttribute('data-singular-price'));
            let resultPrice = count * price;
            priceDiv.innerHTML = `$${resultPrice.toFixed(2)}`
            popupProductEdit[i].setAttribute('data-count', count);
        })
    }

    for (let i = 0; i < popupProductAmountDecrement.length; i++) {
        popupProductAmountDecrement[i].addEventListener('click', e => {
            e.preventDefault();
            let count = decrementFunc(popupProductAmountInput[i], popupProductAmountInput[i].value);
            let priceDiv = popupProductEdit[i].querySelector('.popup_edit_products--product--info_price');
            let price = Number(popupProductEdit[i].getAttribute('data-singular-price'));
            let resultPrice = count * price;
            priceDiv.innerHTML = `$${resultPrice.toFixed(2)}`
            popupProductEdit[i].setAttribute('data-count', count);
        })
    }

    const incrementFunc = (item, value) => {
        if (value >= 5) {
            value = 5;
            item.value = value;
        } else {
            value++;
            item.value = value;
        }
        return value
    }

    const decrementFunc = (item, value) => {
        if (value >= 2) {
            value--;
            item.value = value;
        } else {
            value = 1;
            item.value = value;
        }
        return value
    }
    //END Change Amount in Current Products

    //Save one time product count
    const saveOneTimeBtn = document.querySelectorAll('.save_one_time--btn');
    for (let i = 0; i < saveOneTimeBtn.length; i++) {
        saveOneTimeBtn[i].addEventListener('click', (e => {
            disableButtons(saveOneTimeBtn[i]);
            e.preventDefault();
            let quantity = popupProductEdit[i].getAttribute('data-count');
            let item_id = popupProductEdit[i].getAttribute('data-id');
            saveOneTimeEditedProducts(item_id, quantity);
        }))
    }

    const saveOneTimeEditedProducts = (item_id, quantity) => {
        showSpinner();
        let showResponseError = false;

        fetch(`/api/subscriptions/${subId}/invoice_items/${item_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + window.spree_api_key
            },
            body: JSON.stringify(
                {
                    "invoice_item": {
                        "quantity": parseInt(quantity) || 1
                    }
                }
            )
        })
            .then((response) => {
                showSpinner()
                if (response.status >= 200 && response.status <= 300) {
                    showSuccess();
                    setTimeout(() => location.reload(), 1000);
                } else {
                    showResponseError = true;
                }
                return response
            })
            .then(response => response.json())
            .then(response => {
                if (showResponseError) {
                    showError(response.error)
                }
            })
        ;
    }


    const subProductsArr = document.querySelectorAll('.sub_product');
    const subEditRemoveBtn = document.querySelectorAll('.sub_product_remove--btn');

    const oneTimeEditBox = document.querySelectorAll('.additional_edit');
    const oneTimeEditBtn = document.querySelectorAll('.one_time_edit--btn');
    const addOneTimeProduct = document.querySelectorAll('.add_one_time--btn');


    //Show Additional block for One Time Products
    for (let i = 0; i < oneTimeEditBtn.length; i++) {
        oneTimeEditBtn[i].addEventListener('click', (e => {
            e.preventDefault();
            if (!oneTimeEditBox[i].classList.contains('is-active')) {
                oneTimeEditBtn[i].innerHTML = `<span>Close</span>`;
            } else {
                oneTimeEditBtn[i].innerHTML = `<span>Edit</span>`;
            }
            oneTimeEditBox[i].classList.toggle('is-active');
        }))
    }

    //One Time Add Button
    for (let i = 0; i < addOneTimeProduct.length; i++) {
        addOneTimeProduct[i].addEventListener('click', (e => {
            e.preventDefault();
            editProductPopup.classList.remove('is-active');
        }))
    }

    //Remove one time product count
    const removeOneTimeBtn = document.querySelectorAll('.remove_one_time--btn');
    for (let i = 0; i < removeOneTimeBtn.length; i++) {
        removeOneTimeBtn[i].addEventListener('click', (e => {
            disableButtons(removeOneTimeBtn[i]);
            e.preventDefault();
            let item_id = popupProductEdit[i].getAttribute('data-id');
            removeOneTimeEditedProducts(item_id);
        }))
    }

    const removeOneTimeEditedProducts = (item_id) => {
        showSpinner();
        let showResponseError = false;

        fetch(`/api/subscriptions/${subId}/invoice_items/${item_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + window.spree_api_key
            },
            body: JSON.stringify({})
        })
            .then((response) => {
                showSpinner()
                if (response.status >= 200 && response.status <= 300) {
                    showSuccess();
                    setTimeout(() => location.reload(), 1000);
                } else {
                    showResponseError = true;
                }
                return response
            })
            .then(response => response.json())
            .then(response => {
                if (showResponseError) {
                    if (response.error) {
                        showError(response.error)
                    }

                }
            })
        ;
    }


    //Remove Sub Product
    for (let i = 0; i < subEditRemoveBtn.length; i++) {
        subEditRemoveBtn[i].addEventListener('click', (e => {
            disableButtons(subEditRemoveBtn[i]);
            e.preventDefault();
            editProductPopup.classList.remove('is-active');
            let price_id = subProductsArr[i].getAttribute('data-price-id');
            removeSubProducts(price_id);
        }))
    }

    //Remove Sub Product Fetch
    const removeSubProducts = (price_id) => {
        showSpinner();
        let showResponseError = false;
        fetch(`/api/subscriptions/${subId}/subscription_items/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + window.spree_api_key
            },
            body: JSON.stringify({subscription_item: {price_id: price_id}})
        })
            .then((response) => {
                showSpinner()
                if (response.status >= 200 && response.status <= 300) {
                    showSuccess();
                    setTimeout(() => location.reload(), 1000);
                } else {
                    showResponseError = true;
                }
                return response
            })
            .then(response => response.json())
            .then(response => {
                if (showResponseError) {
                    if (response.errors) {
                        showError(response.errors[0])
                    }

                }
            })
        ;
    }

    const editShippingAddressForm = document.querySelector('#shipping_address_form');
    const editBillingAddressForm = document.querySelector('#billing_address_form');
    const editAccountInfoForm = document.querySelector('.edit_account_info form');

    const disabledFunc = (form) => {
        if (form) {
            form.addEventListener('submit', (e => {
                let button = form.querySelector('button');
                disableButtons(button);
                showSpinner();
                //form.submit()
            }))
        }
    }

    disabledFunc(editShippingAddressForm);
    disabledFunc(editBillingAddressForm);
    disabledFunc(editAccountInfoForm);

    const disableButtons = (button) => {
        button.disabled = button.disabled !== 'true';
    }

    const addSubBtn = document.querySelector('.add_sub_product--btn');
    if (addSubBtn) {
        addSubBtn.addEventListener('click', (e => {
            e.preventDefault();
            editProductPopup.classList.remove('is-active');
        }))
    }
});